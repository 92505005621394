body, html {
    background-color: #F4F3F7;
    font-family: 'IBM Plex Sans', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

a {
    color: inherit;
}

a:hover {
    color: inherit;
    text-decoration: none;
    font-weight: bolder;
}