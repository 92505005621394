.color-nav {
    top: 0px;
    background-position: 0%; 
    background-size: 0%; 
    background-repeat: no-repeat; 
    background-clip: padding-box; 
    opacity: 1;
    position: sticky;
}

#contact-link {
    list-style-type: none; 
    letter-spacing: -0.9px;
    color: #3C21B3;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: xx-large;
    padding-right: 15px;
    opacity: 1;
}

#team-link {
    list-style-type: none; 
    letter-spacing: -0.9px;
    color: #3C21B3;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: xx-large;
    padding-right: 25px;
    opacity: 1;
}

#contact-link:hover {
    color: #3C21B3;
    font-weight: bold;
    opacity: 1;
}

#signup-link {
    list-style-type: none; 
    letter-spacing: -0.9px;
    color: #3C21B3;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: xx-large;
    border: 5px solid #3C21B3;
    opacity: 1;
}

#signup-link:hover {
    color: #3C21B3;
    font-weight: bold;
}

.navbar {
    min-height: 80px;
    padding: 25px; 
    margin: 0; 
  }
  
  .navbar-brand {
    padding: 20px 20px;
    height: 100px;
    line-height: 80px;
  }
  
  .navbar-toggle {
    /* (80px - button height 34px) / 2 = 23px */
    margin-top: 23px;
    padding: 9px 10px !important;
  }
  
  @media (min-width: 768px) {
    .navbar-nav > li > a {
      /* (80px - line-height of 27px) / 2 = 26.5px */
      padding-top: 26.5px;
      padding-bottom: 26.5px;
      line-height: 27px;
    }
  }