.privacy-headers {
    font-size: 15px; 
    font-weight: bold;
    color: #3C15B3;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    color: #3C15B3;
}

#privacy-div {
    background-color: #F4F3F7;
}

#privacy-title {
    font-size: 40px; 
    font-weight: bolder;
    color: #3C15B3;
}