.footer {
    background-color: #3C15B3;
    text-align: center;
    margin: 0;
}

.footer-head {
    color: white;
}

.social-links {
    margin: 5px;
    margin-bottom: 0px;
}

#copyright-tag {
    font-family: 'IBM Plex Sans', sans-serif;  
    font-weight: lighter; 
    color: #14D4BD; 
    margin-top: 20px;
    margin-bottom: 15px;
}

#horizontal-list {
    list-style: none;
}

#link-item {
    list-style-type: none;
    display: inline;
}

#list-item-link {
    font-family: 'IBM Plex Sans', sans-serif;  
    font-weight: lighter; 
    color: #14D4BD;
}