.index-page {
    margin: auto;
    background-color: #F4F3F7;
}

.triangleBackground {
    background: url('../static/backgroundTriangle.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.index-custom {
    background: transparent;
}

#index-h1-lg {
    font-size: 90px;
}

#index-h1-sm {
    text-align: center; 
    font-size: 45px;
}

#index-h3-lg {
    font-size: 25px;
    font-weight: 320;
}

#index-h3-sm {
    text-align: center; 
    font-size: 20px;
    font-weight: 320;
}

#index-enclosing-tag {
    background-color: #F4F3F7;
}

#word-emphasis {
    color: #3C15B3;
}